import React from 'react';
import './../Pages css/Success.css';
import InfoCard from '../infoCard';
import Button from '../Button';
import { saveAs } from 'file-saver';
import ReactGA from 'react-ga';



export default function Success() {

    const download = () => {

        saveAs(
            "https://onedrive.live.com/download?cid=A67C15D6B54C8ACB&resid=A67C15D6B54C8ACB%21352&authkey=ABFlGwoDKLW-mTo",
            "A51 Windows Installer"
        );
        //Track Downloads
        ReactGA.event ({
            category: 'Button',
            action: "Click download for Windows button"
        })
        //End track downloads
    };

    return (
        <div className='success'>
            <h1>Next steps</h1>
            <div className='cards'>
                <div>
                    <InfoCard
                        text='Download A51'
                        color='white'
                        textColor='white'
                    ></InfoCard>
                </div>
                <div>
                    <InfoCard
                        text='Sign in'
                        color='white'
                        textColor='white'
                    ></InfoCard>
                </div>
                <div>
                    <InfoCard
                        text='Experience your photos'
                        color='white'
                        textColor='white'
                    ></InfoCard>
                </div>
            </div>
            <Button text='Download for Windows' onClick={download} backgroundColor='white' color='black' borderColor="#ffffff"></Button>
        </div>
    );
}
