import React, { useState } from 'react';
import { db, collection, addDoc } from '../../Firebase';
import Button from '../Button';
import './../Pages css/PhotoInfo.css';
import {getAuth} from "firebase/auth"
import GetFormData from './GetFormData';
import CommerceUpload from './Commerce Upload';

const PhotoInfo = () => {
  GetFormData()

  const auth = getAuth();
  const user = auth.currentUser;
 
  const [CommerceUI, setCommerceUI] = useState(null);
  console.log(CommerceUI);

    const saveAnswer = (event) => {
        event.preventDefault();
    
        const elementsArray = [...event.target.elements];
    
        const formData = elementsArray.reduce((accumulator, currentValue) => {
          if (currentValue.id) {
            accumulator[currentValue.id] = currentValue.value;
          }
    
          return accumulator;
        }, {});
    
        // db.collection("SurveyResponses").add(formData);
        try {
            const docRef = addDoc(collection(db, `${user.email}`), {
              formData: formData,
              Title: formData.Title,
              Price: formData.Price,
              Height: formData.Height,
              Width: formData.Width,
              Count: localStorage.formCount

            }).then(alert('Your information has been submitted successfuly')).then(setCommerceUI('true'))
            // console.log("Document written with ID: ", docRef.id);
            // console.log("Document written with ID: ", formData.Title);

          } catch (e) {
            console.error("Error adding document: ", e);
            alert("Your information could not be submitted, please make sure you are logged in");
          }

      };
    return (
        <div className='photoInfo'>
            <h1>
                Photo details
            </h1>
            <form onSubmit={saveAnswer} onstyle={{alignItems:"center"}}>
                <div className='title'>
                    <input type="text" id="Title" placeholder="Title" required='true' style={{padding:"1vh", width:"30vh", margin:"1rem"}}></input>
                </div>
                <div className='price'>
                    <input type="number" id="Price" placeholder="Price" required='true' style={{padding:"1vh", margin:"1rem"}}></input>
                </div>
                <div className='size'>
                    <input type="number" id="Height" placeholder="Height (in)" required='true' style={{padding:"1vh", width:"15vh", margin:"1rem", marginRight:"0rem"}}></input>
                    <input type="number" id="Width" placeholder="Width (in)" required='true' style={{padding:"1vh", width:"15vh", margin:"1rem", marginLeft:"0rem"}}></input>
                </div>
                <div>
                    <button style={{
                        margin:"1rem",
                        padding:"1vh",
                        backgroundColor:"#00D725",
                        color:"white",
                        borderRadius:"7px",
                        borderColor:"black",
                        fontFamily: "navigo, sans-serif",
                        fontSize: "15px"
                        }}>
                            Submit
                    </button>
                </div>
                {/* <Button text="Submit" style={{color:"white"}}></Button> */}
                {/* <Button text="Try the demo" onClick={saveAnswer} backgroundColor="none" color="white" borderColor="#ffffff70"></Button> */}

            </form>
        </div>
    )
}

export default PhotoInfo;