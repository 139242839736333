 //import React, { Component } from 'react';
 import './../../App.css'
 import './../Pages css/Commerce Upload.css'
// import axios from 'axios';
 import Button from './../Button'
 import Input from './../Input'
import {useState} from 'react';
//import {render} from 'react-dom';
import { storage, ref, uploadBytes } from './../../Firebase';
import InfoCard from './../infoCard';
import Success from './Success';
import AuctionSuccess from './AuctionSuccess';
// import {setCommerceUI} from './PhotoInfo';



const CommerceUpload = () => {
    const [image, setImage] = useState(null);

    const [CommerceUI, setCommerceUI] = useState('true');

    const handleChange = e => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    var [count, setCount] = useState(0);
    //change allowedPhotos to increace or decreace number of photos that can be uploaded
    var allowedPhotos = 1
    var photoCount = count
    var photos

    count === 1 ? (photos = "photo") : (photos = "photos")

    const handleUpload = () => {
        
        localStorage.setItem("count", count);
        setCount(count + 1);
        setCommerceUI(null);
        console.log('connmerce UI', CommerceUI);


        localStorage.count >= allowedPhotos ? ((localStorage.count = 0) && (setCount(count = 0))) : (count = localStorage.count)
        count >= allowedPhotos ? (setCount(0)) : (count = count)
            //change "count >= 4" to "count >= 3" for perfect continuous looping 
            //change "count >= allowedPhotos" to "count >= 3" for perfect continuous looping 


        // console.log("local storage", localStorage.count);
        // console.log("count", count);
        // console.log('photoCount:',photoCount);
       

        const uploadTask = ref(storage, `images/${localStorage.Gmail}/Auction/${localStorage.photoFormCount}/image`);
        uploadBytes(uploadTask, image).then( 
            () => localStorage.setItem("photoFormCount", localStorage.photoFormCount +1).then( alert('Your file has been uploaded!'))).then(
            (snapshot) => {},
            error => {
                console.log(error);
            },
            () => {
                storage
                .ref("images")
                .getDownloadURL()
                .then(url => {
                    console.log(url);
                });
            }
        );
        
    };

    console.log('image: ', image)

    
    return(

        count === allowedPhotos ? (
            <AuctionSuccess/>
        ) : (

            <div className="commerceUpload">
                <div className='completion'>
                    <div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'#00D725', margin:'2rem', marginBottom:'4rem'}}></div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'#00D725', margin:'2rem', marginBottom:'4rem'}}></div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'white', margin:'2rem', marginBottom:'4rem'}}></div>
                    </div>
                </div>
                <h1>
                    Upload a photo of your work
                </h1>
                <div className='commerceRow1'>
                    <div className='commerceInput'>
                        <Input ref={fileInput => this.fileInput = fileInput} type='file' className='pick-file' onChange={handleChange} backgroundColor="#EFEFEF" color="black" borderColor="#EFEFEF"></Input>
                    </div>
                    <div className='commerce-upload-button'>
                        {/* <button onClick={handleUpload}></button> */}
                        <Button classname='upload-file' text="Upload" backgroundColor="#00D725" color="white" onClick={handleUpload} borderColor="#00D725" margin='1rem' padding='1vh'></Button>
                    </div>
                </div>
                {/* <div className='txt'>
                    <h1 style={{color:"white"}}>You have uploaded</h1>
                    <h1 style={{color:"#00D725", marginRight:".5rem", marginLeft:".5rem"}}>{photoCount}</h1>
                    <h1 style={{color:"white"}}>{photos}</h1>
                </div> */}
            </div>
        )
    );

    
};
export default CommerceUpload