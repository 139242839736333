import React from 'react'; 
import BlogCard from '../Blog Card';
import './../../App.css' 
import './../Pages css/Blog.css' 

 
 

export default function Blog(){ 

    return ( 
        <div className='blog-container'>
            <div className='title'>
                <BlogCard 
                    path="/" 
                    header="Blog" 
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor " 
                    backgroundColor="white" 
                    color="black">
                </BlogCard>
            </div>
        </div>
    ); 
} 

 