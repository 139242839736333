import React, { useEffect, useState } from 'react';
import { db, collection} from '../../Firebase';
import { onSnapshot, query, where} from "firebase/firestore";
import './../Pages css/GetFormData.css';
import { getAuth } from 'firebase/auth';


const GetFormData = () => {

    const auth = getAuth();
    const user = auth.currentUser;

    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // const getPostsFromFirebase = [];
        // const subscriber = collection(db, "Ben.hayden.sk8@gmail.com")
        // .onSnapshot((querySnapshot) => {
        //     querySnapshot.forEach(doc => {
        //         getPostsFromFirebase.push({
        //             ...doc.data(), //spread opperator
        //              key: doc.id, //id is given by Firebase
        //         });
        //     });
        //     setPosts(getPostsFromFirebase);
        //     setLoading(false);
        // });

        const q = query(collection(db, "Auction Forms Collection", "Auction Forms Document", "Auction Forms"))//, where("Title", "!=", ""));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const getPostsFromFirebase = [];
        // const cities = [];
        querySnapshot.forEach((doc) => {
            getPostsFromFirebase.push({
                            ...doc.data(), //spread opperator
                             key: doc.id, //id is given by Firebase
                        });
            // cities.push(doc.data().name);
        });
            setPosts(getPostsFromFirebase);
            setLoading(false);
        console.log(`Forms from: ${user.email}`, getPostsFromFirebase);
        console.log ("Array length", getPostsFromFirebase.length); // gets the number of forms a logged in user has submitted with the where() parameter
        localStorage.setItem("formCount", (getPostsFromFirebase.length + 1)); //stores the number of forms a user has submited to local storage
        localStorage.setItem("photoFormCount", getPostsFromFirebase.length); // = to formCount but doesn't update until photo is uploaded
        console.log("photoFormCount:", localStorage.photoFormCount);
        console.log("formCount:", localStorage.formCount);

        });


        //return cleanup function
        return () => unsubscribe();
    }, []); //empty dependancies array so useEffect only called once

// UI Starts Here ********************

    // if (loading) {
    //     return(
    //         <h1>
    //             loading...
    //         </h1>
    //     )
    // }

    // return(
    //     <div>
    //         <h1 className="getFormData">
    //             Hello world
    //         </h1>
    //         {posts.length > 0 ? (
    //             posts.map((post) => <div key={post.key}>{post.answer}</div>)
    //         ) : (
    //             <div>No posts yet</div>
    //         )}
    //     </div>
    // );

// UI Ends Here ********************

};

export default GetFormData;

