import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
// import { browserHistory } from 'react-router';
import './App.css'
// import Hook from './Components/Pages js/Hook';
// import Footer from './Components/Pages js/Footer';
import Navbar from './Components/Navbar';
// import Benefits from './Components/Pages js/Benefits';
// import SignUp from './Components/Pages js/signUp';
// import Subscribe from './Components/Pages js/Subscribe';
// import MailChimp from './Components/Pages js/MailChimp';
// import Typform from './Components/Pages js/Typeform';
// import MailChimp2 from './Components/Pages js/MailChimp2'
// import Demo from './Components/Pages js/Demo';
import Upload from './Components/Pages js/Upload2';
// import Home from './Components/Pages js/Home';
import Blog from './Components/Pages js/Blog';
import ReactGa from 'react-ga';
// import NavbarAlert from './Components/Pages js/NavbarAlert';
import SignIn from './Components/Pages js/Google Sign In/GoogleSignIn2';
import Home2 from './Components/Pages js/Home2';
import Footer from './Components/Pages js/Footer';
import ExampleWebGL from './Components/Pages js/Galleries/ExampleWebGL'
import PhotoGallery from './Components/Pages js/PhotoGallery';
import PhotoInfo from './Components/Pages js/PhotoInfo';
import FirebaseAuth from './Components/Pages js/Firebase Authentication/FirebaseAuth';
import Success from './Components/Pages js/Success';
import Faqs from './Components/Pages js/Faqs';
import CommerceUpload from './Components/Pages js/Commerce Upload';
import AuctionForm from './Components/Pages js/AuctionForm';
import Product from './Components/Pages js/Product';
import ColorPick from './Components/Pages js/Color Pick';
import WallUpload from './Components/Pages js/Wall Upload';
import WallPick from './Components/Pages js/Wall Pick';

//user gallery imports
import One from './Components/Pages js/Galleries/User Galleries/000001';
import Zx9y32 from './Components/Pages js/Galleries/User Galleries/zx9y32';
import Bixg6 from './Components/Pages js/Galleries/User Galleries/5bixg6';
import Fborxg from './Components/Pages js/Galleries/User Galleries/fborxg';
import Yn15vm from './Components/Pages js/Galleries/User Galleries/yn15vm';
import Jqhva from './Components/Pages js/Galleries/User Galleries/7jqhva';
import Ja5tw2 from './Components/Pages js/Galleries/User Galleries/ja5tw2';
import Ws9fwi from './Components/Pages js/Galleries/User Galleries/ws9fwi';
import Olw7p5 from './Components/Pages js/Galleries/User Galleries/olw7p5';
import Roig from './Components/Pages js/Galleries/User Galleries/Roig';

//*********************** */

// import Test from './Components/Pages js/Galleries/User Galleries/iframe gallery test';

//*********************** */



function App() {

    useEffect(() => {
        ReactGa.initialize('UA-216217527-1')

        //To report page view in Google analytics
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])


    return (
        <>
            <HashRouter>
                {/* <NavbarAlert/> */}
                <Navbar />
                {/* <GoogleSignIn/> */}
                {/* <Hook/> */}
                {/* <Benefits/> */}
                {/* <Demo/> */}
                {/* <SignUp /> */}
                {/* <Subscribe/> */}
                {/* <Typform/> */}
                {/* <MailChimp/> */}
                {/* <MailChimp2/> */}
                {/* <Footer/> */}


                <Switch>
                    <Route path='/' exact component={Home2} />
                    <Route path='/Upload' href='/Upload' component={Upload} />
                    <Route path='/Blog' component={Blog} />
                    <Route path='/SignIn' href='/SignIn' component={SignIn} />
                    <Route path='/Demo' href='/Demo' component={ExampleWebGL} />
                    <Route path='/Gallery' href='/Gallery' component={PhotoGallery} />
                    <Route path='/PhotoInfo' href='/PhotoInfo' component={PhotoInfo} />
                    <Route path='/Login' href='/Login' component={FirebaseAuth} />
                    <Route path='/CommerceUpload' href='/CommerceUpload' component={CommerceUpload} />
                    <Route path='/AuctionForm' href='/AuctionForm' component={AuctionForm} />
                    <Route path='/Download' href='/Download' component={Success} />
                    <Route path='/Faqs' href='/Faqs' component={Faqs} />
                    <Route path='/Product' href='/Product' component={Product} />
                    <Route path='/ColorPick' href='/ColorPick' component={ColorPick} />
                    <Route path='/WallUpload' href='/WallUpload'component={WallUpload} />
                    <Route path='/WallPick' href='WallPick' component={WallPick} />
                    <Route path='/Medium' component={() => {
                        window.location.href = 'https://medium.com/@A51';
                        return null;
                    }} />
                    <Route path='/Mission' component={() => {
                        window.location.href = 'https://medium.com/@A51/our-mission-98ad0369260c';
                        return null;
                    }} />
                    <Route path='/Instagram' component={() => {
                        window.location.href = 'http://instagram.com/a51.studio';
                        return null;
                    }} />
                    <Route path='/LinkedIn' component={() => {
                        window.location.href = 'https://www.linkedin.com/company/a51studios/';
                        return null;
                    }} />

                    <Route path='/RoigsWork' component={() => {
                        window.location.href = 'https://www.roigcollection.com/shop';
                        return null;
                    }} />

                    <Route path='/000001' href='/000001' component={One} />
                    <Route path='/zx9y32' href='/zx9y32' component={Zx9y32} />
                    <Route path='/5bixg6' href='/5bixg6' component={Bixg6} />
                    <Route path='/fborxg' href='/fborxg' component={Fborxg} />
                    <Route path='/yn15vm' href='/yn15vm' component={Yn15vm} />
                    <Route path='/7jqhva' href='/7jqhva' component={Jqhva} />
                    <Route path='/ja5tw2' href='/ja5tw2' component={Ja5tw2} />
                    <Route path='/ws9fwi' href='/ws9fwi' component={Ws9fwi} />
                    <Route path='/olw7p5' href='/olw7p5' component={Olw7p5} />
                    <Route path='/Roig' href='/Roig' component={Roig} />

                    {/* <Route path='/test' href='/test' component={Test}/> */}

                </Switch>
                <Footer />
            </HashRouter>
        </>
    )
}

export default App;