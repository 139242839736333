import React, { useState } from 'react';
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";
import Button from '../../Button';
import './../../Pages css/FirebaseAuth.css';
import { auth } from 'google-auth-library';



function FirebaseAuth () {

const provider = new GoogleAuthProvider();

const auth = getAuth();
const user = auth.currentUser;

const [login, setLogin] = useState(true);
const [logout, setLogout] = useState('none');

const signIn = () => {


    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        console.log(user);

        setLogin('none');
        setLogout(true);

        localStorage.setItem("Gmail", user.email);
        console.log('local storage email:', localStorage.Gmail);

      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
};

const logOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.

      setLogin(true);
      setLogout('none');

      console.log("you have been signed out");

      // refresh the page after sign out
      window.location.reload(false);

    }).catch((error) => {
      // An error happened.
    });
};

    // return(
    //     <div className='firebaseAuth'>
    //       <div className='login' style={{display:login}}>
    //         <Button text='Sign in' onClick={signIn} backgroundColor='#707F8C' color='white' borderColor='#707F8C'></Button>
    //       </div>
    //       <div className='logout' style={{display:logout}}>
    //         <Button text='Sign out' onClick={logOut} backgroundColor='white' color='black' borderColor='white'></Button>
    //         <Button path='/Upload' text='Upload' backgroundColor='white' color='black' borderColor='white'></Button>

    //       </div>
    //     </div>        
    // )

    //***************** */
    return(
      user === null ? (
        <div className='firebaseAuth'>
            <div className='firebaseLogin'>
              <Button text='Sign in' onClick={signIn} backgroundColor='black' color='white' borderColor='black' width='10rem'></Button>
            </div>
          </div>
        ):(
        <div className='firebaseAuth'>
          <div className='firebaseLogout'>
            <Button text='Sign out' onClick={logOut} backgroundColor='black' color='white' borderColor='black'width='10rem'></Button>
            <br></br>
            <Button path='/Upload' text='Upload' backgroundColor='white' color='black' borderColor='white' height='2.5rem'></Button>  
          </div>
        </div>
        )
    )
    //***************** */


}

export default FirebaseAuth;
