import React from 'react';
import './../Pages css/PhotoGallery.css'

export default function PhotoGallery() {
    return (
        <div className='gallery-container'>
            <h1>
                Gallery
            </h1>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FA51%20Gallery%20Screenshots%2FUntitled-1%20copy.jpg?alt=media&token=9637e929-91ef-409d-8ca7-b2b9038fcc7e"
                    alt='Wide view of gallery'
                    height={200}
                ></img>
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FA51%20Gallery%20Screenshots%2FUntitled-2.jpg?alt=media&token=e4316515-c39a-4b5f-9ee7-f3ecda839b00"
                    alt='Wide view of gallery'
                    height={200}
                ></img>
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FA51%20Gallery%20Screenshots%2FUntitled-3.jpg?alt=media&token=32406c70-44ec-4d77-b448-80c23bc65cf9"
                    alt='Wide view of gallery'
                    height={200}
                ></img>
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FA51%20Gallery%20Screenshots%2FUntitled-4.jpg?alt=media&token=fe3ed8f6-b31a-4322-9d7c-497d83982f40"
                    alt='Wide view of gallery'
                    height={200}
                ></img>
            </div>
            <div>
                <img src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Images%2FA51%20Gallery%20Screenshots%2FUntitled-5.jpg?alt=media&token=39a932d9-a2fb-40de-909e-99bd50213175"
                    alt='Wide view of gallery'
                    height={200}
                ></img>
            </div>
            
        </div>
    );
}