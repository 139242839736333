import './../../App.css'
import Button from './../Button'
import Input from './../Input'
import {useState} from 'react';
import { storage, ref, uploadBytes } from './../../Firebase';
import AuctionSuccess from './AuctionSuccess';
import './../Pages css/Wall Upload.css';



const WallUpload = () => {
    const [image, setImage] = useState(null);

    const handleChange = e => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    var [count, setCount] = useState(0);
    //change allowedPhotos to increace or decreace number of photos that can be uploaded
    var allowedPhotos = 1
    var photoCount = count
    var photos

    count === 1 ? (photos = "photo") : (photos = "photos")

    const handleUpload = () => {
        
        localStorage.setItem("count", count);
        setCount(count + 1);
       


        localStorage.count >= allowedPhotos ? ((localStorage.count = 0) && (setCount(count = 0))) : (count = localStorage.count)
        count >= allowedPhotos ? (setCount(0)) : (count = count)
       

        const uploadTask = ref(storage, `images/${localStorage.Gmail}/Wall/${localStorage.wall}/image`);
        uploadBytes(uploadTask, image).then( 
            () => alert('Your file has been uploaded!')).then(
            (snapshot) => {},
            error => {
                console.log(error);
            },
            () => {
                storage
                .ref("images")
                .getDownloadURL()
                .then(url => {
                    console.log(url);
                });
            }
        );
        
    };

    console.log('image: ', image)

    
    return(

        count === allowedPhotos ? (
            <AuctionSuccess/>
        ) : (

            <div className="wallUpload">
                <h1>
                    Upload your custom wall image
                </h1>
                <div className='wallRow1'>
                    <div className='wallInput'>
                        <Input ref={fileInput => this.fileInput = fileInput} type='file' className='pick-file' onChange={handleChange} backgroundColor="#EFEFEF" color="black" borderColor="#EFEFEF"></Input>
                    </div>
                    <div className='wall-upload-button'>
                        {/* <button onClick={handleUpload}></button> */}
                        <Button classname='upload-file' text="Upload" backgroundColor="#00D725" color="white" onClick={handleUpload} borderColor="#00D725" margin='1rem' padding='1vh'></Button>
                    </div>
                </div>
            </div>
        )
    );

    
};
export default WallUpload