import React from 'react';
// import './../App.css'
import './Pages css/Input.css'
// import { Link } from 'react-router-dom';


export default function Input(props){
    return (
        <div className= "Input">
            <input type={props.type} onChange={props.onChange} className="link" style={{display:props.display, backgroundColor:props.backgroundColor, textDecoration: 'none', color:props.color }}>
         
            </input> 
        </div>
    );
}