import firebase from "firebase/compat/app";
// import 'firebase/storage';
import { getStorage, ref, uploadBytes } from "firebase/storage";
// import 'firebase/firestore';
import {getFirestore, collection, addDoc, onSnapshot} from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCaOrhmmwrKNShfapxguXdbpixf6shf5WA",
    authDomain: "a51.studio",
    // authDomain: "a51-database-v2.firebaseapp.com",
    projectId: "a51-database-v2",
    storageBucket: "a51-database-v2.appspot.com",
    messagingSenderId: "113152191868",
    appId: "1:113152191868:web:5dded83f0d18e64251c8f9",
    measurementId: "G-QMY2VKES07"
  };

  firebase.initializeApp(firebaseConfig)
  
  const storage = getStorage();
//   const storage = firebase.storage();

  // const db = firebase.firestore();
  const db = getFirestore();
  // db is for firestore storage (Form data)

  export {storage, db, collection, onSnapshot, addDoc, ref, uploadBytes, firebase as default};