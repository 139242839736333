import React from 'react';
// import './../App.css'
import './Pages css/Outlined Button.css'
import { Link } from 'react-router-dom';


export default function OutlinedButton(props){
    return (
        <>
            <nav>
                <div className= "Outlined-button">
                    <Link to={props.path} onClick={props.onClick} className="link" style={{ backgroundColor:props.backgroundColor, textDecoration: 'none', color:props.color, borderColor:props.borderColor, padding:props.padding, marginTop:props.marginTop}}>
                        <h1>
                            {props.text}
                        </h1>
                    </Link>
                </div>
            </nav>
        </>
       
    );
}