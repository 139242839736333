import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import './../../Pages css/Galleries.css';
import Button from "../../Button";
import ReactGA from 'react-ga';







const signUp = () => {
  ReactGA.event ({
      category: 'Button',
      action: "Click Sign up button"
  })
}

function ExampleWebGL() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "/AnalyticsBuild/Build7.loader.js",
    dataUrl: "/AnalyticsBuild/Build7.data",
    frameworkUrl: "/AnalyticsBuild/Build7.framework.js",
    codeUrl: "/AnalyticsBuild/Build7.wasm"
  });
  return (
      <div className="example-gallery">
          <div>
            <Unity unityProvider={unityProvider} style={{ width: 1200, height: 600 }}/>;
          </div>
            <div className="text">
                {/* <h1>
                    Try the demo
               </h1> */}
               <div>
                   <p1>
                       Click inside the demo
                   </p1>
                   <p2>
                       Click and drag to pan
                   </p2>
                   <p3>
                      WASD to move
                   </p3>
               </div>
            </div>
            <div className="signUp">
              <Button className='signUp-button' path="/Login" text="Sign up" onClick={signUp} backgroundColor="#008AE6" color="white" borderColor="#008AE6"></Button>
            </div>
      </div>
     
  );
}

export default ExampleWebGL;
