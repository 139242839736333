import React from 'react'; 
// import './../../App.css';
import './../Pages css/Hook.css';


export default function Hook(){ 

    return ( 
        <div className="hook-container"> 
           {/* <div className="hook-text">  */}
               <h1> 
                Experience your photos 
               </h1> 
            {/* </div>   */}
        </div> 
    ); 

} 