import React, { useState } from 'react';
import './../Pages css/Wall Pick.css';
import WallUpload from './../Pages js/Wall Upload'


const WallPick = () => {

const [Wall, setWall] = useState(null);
const [WallSubmit, setWallSubmit] = useState(null);


    // localStorage.setItem("wall", null);
    console.log(localStorage.wall);

    const RadioSubmit = () => {
        setWallSubmit('true')
    }
    
    // const Wall = () => {
    //     if (localStorage.wall == 1) {
    //         console.log("success");
    //     }
    //     else{
    //         if (localStorage.wall == 2) {
    //             console.log("success");
    //         }
    //         else{
    //             if (localStorage.wall == 3) {
    //                 console.log("success");
    //             }
    //             else{
    //                 console.log('error');
    //             }
    //         }
    //     }
    // }

    // localStorage.wall == null ? (
    //     alert("you must pick a wall number")
    // ):(
    //     setWall('true')
    // )

    const One = ()  => {
        localStorage.setItem("wall", 1);
        console.log(localStorage.wall);
        setWall('true')
    }

    const Two = () => {
        localStorage.setItem("wall", 2);
        console.log(localStorage.wall);
        setWall('true')
    }

    const Three = () => {
        localStorage.setItem("wall", 3);
        console.log(localStorage.wall);
        setWall('true')
    }

    return(

        Wall === 'true' && WallSubmit === 'true' ? (
            <WallUpload/>
        ):(

        <div className='wallpick'>
            <div className='wallpick-everything'>
            <h1>Select which wall to customize</h1>
            <form onSubmit={RadioSubmit} style={{marginLeft:'10rem'}}>
                <div style={{alignItems:'center', marginLeft:'0rem', padding:'1rem'}}>
                    <input type='radio' id='1' name='wallNumber' value='one' onChange={One}></input> 1
                    <br/>
                    <input type='radio' id='2' name='wallNumber' value='two' onChange={Two}></input> 2
                    <br/>
                    <input type='radio' id='3' name='wallNumber' value='three' onChange={Three}></input> 3
                </div>
                <button style={{
                    margin:"1rem",
                    padding:"1vh",
                    backgroundColor:"#00D725",
                    color:"white",
                    borderRadius:"7px",
                    borderColor:"black",
                    fontFamily: "navigo, sans-serif",
                    fontSize: "15px"
                        }}>
                            Submit
                </button>
            </form>
            </div>
            
        </div>
        )
    )
}

export default WallPick;