import React, {useEffect, useState} from 'react';
import './../Pages css/Product.css';
import GetFormData from './GetFormData';
import Button from './../Button';
import FirebaseAuth from './../Pages js/Firebase Authentication/FirebaseAuth.js';
//Firebase storage imports
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
//End Firebase storage imports
//form data imports
import { onSnapshot, query, where} from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { db, collection} from '../../Firebase';
//end form data imports

function Product() {


//Begin get form data****************************************************************************
GetFormData()

    const auth = getAuth();
    const user = auth.currentUser;

    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {

        // await GetFormData();

        const q = query(collection(db, "Auction Forms Collection", "Auction Forms Document", "Auction Forms"), where("Count", "==", `${localStorage.photoFormCount}`));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const getPostsFromFirebase = [];
        // const cities = [];
        querySnapshot.forEach((doc) => {
            console.log("Title:", doc.data().Title); //logs the document title to the console
            localStorage.setItem("productTitle", doc.data().Title); //stores the document title in local storage
            console.log("Product title:", localStorage.productTitle);
            // console.log("Price:", doc.data().Price);
            localStorage.setItem("productPrice", doc.data().Price);
            // console.log("Height:", doc.data().Height);
            localStorage.setItem("productHeight", doc.data().Height);
            // console.log("Width:", doc.data().Width);
            localStorage.setItem("productWidth", doc.data().Width);
            localStorage.setItem("productInfo", doc.data().Info);

            getPostsFromFirebase.push({
                            ...doc.data(), //spread opperator
                             key: doc.id, //id is given by Firebase
                        });
            // cities.push(doc.data().name);
        });
            setPosts(getPostsFromFirebase);
            setLoading(false);
        console.log(`Forms from: ${user.email}`, getPostsFromFirebase);

        });


        //return cleanup function
        return () => unsubscribe();
    }, []); //empty dependancies array so useEffect only called once

// };

//End get form data******************************************************************************

//Begin Firebase storage reference

    const storage = getStorage();

    //get download URL form Firebase Storage
    getDownloadURL(ref(storage, `images/${user.email}/Auction/${localStorage.photoFormCount}/image`)).then((url) => {
        // console.log(url);
        localStorage.setItem("productURL", url); //Store the dowload URL to local storage
    }).catch((error) => {
        console.log("catch")
    }  
    )

    
   


//End Firebase storage referance

    return (
        <div className='product'>
            <h1>
                {localStorage.productTitle}
            </h1>
            <div className='productRow'>
                <div className='image'>
                    <img src={localStorage.productURL}
                        height={500}
                        alt='Product Image'
                    ></img>
                </div>
                <div className='data'>
                    {/* <h1>
                        Artist: <p1>{user.email}</p1>
                    </h1> */}
                    <h1>
                        Price: <p1>${localStorage.productPrice}</p1>
                    </h1>
                    <h1>
                        Size: <p1>{localStorage.productHeight}" X {localStorage.productWidth}"</p1>
                    </h1>
                    <h1>
                        Info: <p1>{localStorage.productInfo}</p1>
                    </h1>
                    <div style={{marginTop:'5rem'}}>
                        <Button text='Buy' backgroundColor='black' color='white' borderColor='black' width='15rem'></Button>
                    </div>
                </div>
            </div>                             
        </div>
    );

}

export default Product;