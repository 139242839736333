 //import React, { Component } from 'react';
 import './../../App.css'
 import './../Pages css/Upload.css'
// import axios from 'axios';
 import Button from './../Button'
 import Input from './../Input'
import {useState} from 'react';
//import {render} from 'react-dom';
import { storage, ref, uploadBytes } from '../../Firebase';
import InfoCard from '../infoCard';
import Success from './Success';
import { Link } from 'react-router-dom';



const Upload = () => {
    const [image, setImage] = useState(null);

    const handleChange = e => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    var [count, setCount] = useState(0);
    //change allowedPhotos to increace or decreace number of photos that can be uploaded
    var allowedPhotos = 4
    var photoCount = count
    var photos
    var downloadText

    count === 1 ? (photos = "photo") : (photos = "photos")
    count > 0 ? (downloadText = 'flex') : (downloadText = 'none')

    const handleUpload = () => {
        
        localStorage.setItem("count", count);
        setCount(count + 1);

        localStorage.count >= allowedPhotos ? ((localStorage.count = 0) && (setCount(count = 0))) : (count = localStorage.count)
        count >= allowedPhotos ? (setCount(0)) : (count = count)
            //change "count >= 4" to "count >= 3" for perfect continuous looping 
            //change "count >= allowedPhotos" to "count >= 3" for perfect continuous looping 


        // console.log("local storage", localStorage.count);
        // console.log("count", count);
        // console.log('photoCount:',photoCount);
       

        const uploadTask = ref(storage, `images/${localStorage.Gmail}/${localStorage.count}/image`);
        uploadBytes(uploadTask, image).then(
            () => alert('Your file has been uploaded!')).then(
            (snapshot) => {},
            error => {
                console.log(error);
            },
            () => {
                storage
                .ref("images")
                .getDownloadURL()
                .then(url => {
                    console.log(url);
                });
            }
        );
        
    };

    console.log('image: ', image)

    
    return(

        count === allowedPhotos ? (
            <Success/>
        ) : (

            <div className="Upload">
                <div className='row1'>
                    <div className='input'>
                        <Input ref={fileInput => this.fileInput = fileInput} type='file' className='pick-file' onChange={handleChange} backgroundColor="#EFEFEF" color="black" borderColor="#EFEFEF"></Input>
                    </div>
                    <div className='upload-button'>
                        {/* <button onClick={handleUpload}></button> */}
                        <Button classname='upload-file' text="Upload" backgroundColor="#00D725" color="white" onClick={handleUpload} borderColor="#00D725"></Button>
                    </div>
                </div>
                <div className='txt'>
                    <h1 style={{color:"white"}}>You have uploaded</h1>
                    <h1 style={{color:"#00D725", marginRight:".5rem", marginLeft:".5rem"}}>{photoCount}</h1>
                    <h1 style={{color:"white"}}>{photos}</h1>
                </div>
                <Link to='/Download' style={{textDecoration:'none', color:'#00D725'}}>
                <div className='downloadNow' style={{display:`${downloadText}`}}>
                    <h1>See your {photos} now</h1>
                </div>
                </Link>
                
            </div>
        )
    );

    
};
export default Upload