import React, {useState} from 'react';
//import './../App.css'
import './Navbar.css'
import { Link } from 'react-router-dom';
import Button from './Button';
// import OutlinedButton from './Outlined Button';
import ReactGA from 'react-ga';
import { IconContext } from 'react-icons';
import { SidebarData } from './SidebarData';
import * as FaIcons from 'react-icons/fa';



export default function Navbar(){

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

// eslint-disable-next-line
const redUpload = () => {
    ReactGA.event ({
        category: 'Button',
        action: "Click red upload button"
    })
}

const homeButton = () => {
    ReactGA.event ({
        category: 'Button',
        action: "Click home button"
    })
}

const signIn = () => {
    ReactGA.event ({
        category: 'Button',
        action: "Click Sign in button"
    })
}

    return (
        <>
            <nav>
                <div className="Navbar">
                    <div className= "logo">
                        <Link to="/" onClick={homeButton} className="nav-link" style={{ textDecoration: 'none', color: 'white' }}>
                            {/* <h1>
                                A51
                            </h1> */}
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Logo%2FA51%20Logo%20White.png?alt=media&token=751eb454-5ca9-46ab-8b86-d5674270b66b"
                                alt="A51"
                            ></img>
                        </Link>
                    </div>
                    {/* <div className="upload">
                        <Button className="Button" onClick={redUpload} path="/Upload" text="Upload" backgroundColor="red" color="white" borderColor="red"></Button>
                    </div> */}
                    {/* <div className="blog">
                        <Button path="/Blog" text="Blog" backgroundColor="#000000" color="white"></Button>
                    </div> */}
                    {/* <div className='medium'>
                        <OutlinedButton path="/Medium" text="Blog" backgroundColor="#000000" color="white" borderColor="white"></OutlinedButton>
                    </div> */}
                    <div className="logIn">
                        <Button path="/Login" text="Sign in" onClick={signIn} backgroundColor="White" color="black"></Button>
                    </div>
{/********************* Mobile Nav starts here **********************************/}
<IconContext.Provider value={{ color: '#fff' }}>
       
       <Link to='#' className='menu-bars'>
         <FaIcons.FaBars onClick={showSidebar} />
       </Link>
     <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
       <ul className='nav-menu-items' onClick={showSidebar}>
         <li className='navbar-toggle'>
     

            <Link to="#" className='menu-bars'>
                         <img
                             src="https://firebasestorage.googleapis.com/v0/b/a51-asset-database.appspot.com/o/Icons%2Fcancel%20(1).png?alt=media&token=3d196ca3-7c7d-478a-b256-9ced62e5d566"
                             alt="X"
                             height={20}
                         ></img>
            </Link>


         </li>
         {SidebarData.map((item, index) => {
           return (
             <li key={index} className={item.cName}>
               <Link to={item.path}>
                 {item.icon}
                 <span>{item.title}</span>
               </Link>
             </li>
           );
         })}
       </ul>
     </nav>
   </IconContext.Provider>
{/********************* Mobile Nav starts here **********************************/}
                </div>
            </nav>
        </>
    );
}