import React from 'react';
// import './../../App.css';
import './../Pages css/Benefits2.css';
import InfoCard from '../infoCard';
import Button from './../Button'
import ReactGA from 'react-ga';
// import OutlinedButton from './../Outlined Button';


const viewDemo = () => {
    ReactGA.event ({
        category: 'Button',
        action: "Click Try the demo button"
    })
}

export default function Benefits2() {
    return(
        <div className='benefits'>
            <div className='steps'>
                <h1>
                    In 3 steps
                </h1>
            </div>
            <div className='cards'>
                <div>
                    <InfoCard
                        // to='/SignIn'
                        // color='black' 
                        // text='See your photos from a new perspective'
                        textColor='white'
                        color='black'
                        text='Sign in with Google'
                    ></InfoCard>
                </div>
                <div>
                    <InfoCard 
                        // to='/SignIn'
                        // color='black'
                        // text='Make the viewing process impactful'
                        textColor='white'
                        color='black'
                        text='Upload 4 photos'
                    ></InfoCard>
                </div>
                <div>
                    <InfoCard 
                        // to='/SignIn'
                        // text='Experience your art in a digital museum'
                        textColor='white'
                        color='black'
                        text='Receive a virtual gallery'
                    ></InfoCard>
                </div>
            </div>
            <div className='demo-button'>
                <Button className="demo" path="/Demo" text="Try the demo" onClick={viewDemo} backgroundColor="none" color="white" borderColor="#ffffff70"></Button>
            </div>
        </div>
    );
}