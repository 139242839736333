import React from 'react';
import Button from '../Button';
import './../Pages css/AuctionSuccess.css'

function AuctionSuccess () {
    return (
        <div className='auctionSuccess'>
            <div className='completion'>
                    <div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'#00D725', margin:'2rem', marginBottom:'4rem'}}></div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'#00D725', margin:'2rem', marginBottom:'4rem'}}></div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'#00D725', margin:'2rem', marginBottom:'4rem'}}></div>
                    </div>
                </div>
            <h1>
                All done!
            </h1>
            <div className='auctionSuccessButtons'>
                <div>
                    <Button text="Return to home" path='./' backgroundColor="white" color="black" borderColor="white" margin='1rem' padding='1vh'></Button>
                    {/* <Button text="Upload another" path='./auctionForm' backgroundColor="#00D725" color="white" borderColor="#00D725" margin='1rem' padding='1vh'></Button> */}
                </div>
            </div>
        </div>
    )
}

export default AuctionSuccess;