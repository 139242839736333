import React from 'react';
import Hook from './Hook';
import Benefits2 from './Benefits2';
// import ModalTest from './ModalTestPage';

function Home2 () {
    return (
        <>
            <Hook/>
            {/* <ModalTest/> */}
            <Benefits2/>
        </>
    )
}

export default Home2