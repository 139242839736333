import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import './../../../Pages css/Roig Gallery.css';
import Button from "../../../Button";
import ReactGA from 'react-ga';







const signUp = () => {
  ReactGA.event ({
      category: 'Button',
      action: "Click Buy Roig's Work"
  })
}

function Roig() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "UserGalleries/roig/Roig3.loader.js",
    dataUrl: "UserGalleries/roig/Roig3.data",
    frameworkUrl: "UserGalleries/roig/Roig3.framework.js",
    codeUrl: "UserGalleries/roig/Roig3.wasm"
  });
  return (
      <div className="roig-example-gallery">
          
            <div className="text">
                <h1>
                    Ricardo Roig X A51
               </h1>
               <div>
            <Unity unityProvider={unityProvider} style={{ width: 1200, height: 600 }}/>
          </div>
               <div>
                   <p1>
                       Click inside the demo
                   </p1>
                   <p2>
                       Click and drag to pan
                   </p2>
                   <p3>
                      WASD to move
                   </p3>
               </div>
               
            </div>
            <div className="signUp">
              <Button className='signUp-button' path="/RoigsWork" text="Buy Roig's work" onClick={signUp} backgroundColor="#008AE6" color="white" borderColor="#008AE6"></Button>
            </div>
      </div>
     
  );
}

export default Roig;


// import React from 'react';
// import { Unity, useUnityContext } from "react-unity-webgl";

// function One() {
//   const { unityProvider } = useUnityContext({
//     loaderUrl: "UserGalleries/000001/000001.loader.js",
//     dataUrl: "UserGalleries/000001/000001.data",
//     frameworkUrl: "UserGalleries/000001/000001.framework.js",
//     codeUrl: "UserGalleries/000001/000001.wasm",
//   });

//   return (
//     <div>
//         <h1>hey</h1>
//         <Unity unityProvider={unityProvider} />
//     </div>
//   );
// }

// export default One;