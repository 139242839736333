import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import './../../../Pages css/Galleries.css';
// import Button from "../../Button";
// import ReactGA from 'react-ga';







// const signUp = () => {
//   ReactGA.event ({
//       category: 'Button',
//       action: "Click Sign up button"
//   })
// }

function Ja5tw2() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "UserGalleries/ja5tw2/ja5tw2.loader.js",
    dataUrl: "UserGalleries/ja5tw2/ja5tw2.data",
    frameworkUrl: "UserGalleries/ja5tw2/ja5tw2.framework.js",
    codeUrl: "UserGalleries/ja5tw2/ja5tw2.wasm"
  });
  return (
      <div className="example-gallery">
          <div>
            <Unity unityProvider={unityProvider} style={{ width: 1200, height: 600 }}/>;
          </div>
            <div className="text">
                {/* <h1>
                    Try the demo
               </h1> */}
               <div>
                   <p1>
                       Click inside the demo
                   </p1>
                   <p2>
                       Click and drag to pan
                   </p2>
                   <p3>
                      WASD to move
                   </p3>
               </div>
            </div>
            {/* <div className="signUp">
              <Button className='signUp-button' path="/SignIn" text="Sign up" onClick={signUp} backgroundColor="#008AE6" color="white" borderColor="#008AE6"></Button>
            </div> */}
      </div>
     
  );
}

export default Ja5tw2;


// import React from 'react';
// import { Unity, useUnityContext } from "react-unity-webgl";

// function One() {
//   const { unityProvider } = useUnityContext({
//     loaderUrl: "UserGalleries/000001/000001.loader.js",
//     dataUrl: "UserGalleries/000001/000001.data",
//     frameworkUrl: "UserGalleries/000001/000001.framework.js",
//     codeUrl: "UserGalleries/000001/000001.wasm",
//   });

//   return (
//     <div>
//         <h1>hey</h1>
//         <Unity unityProvider={unityProvider} />
//     </div>
//   );
// }

// export default One;