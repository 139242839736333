import React from 'react';
import { Link } from 'react-router-dom';
// import './../App.css'
import './Pages css/infoCard.css'


export default function InfoCard(props){
    return (
        <>
            <nav>
                <div className= "infoCard" style={{ backgroundColor:props.backgroundColor, textDecoration: 'none', color:props.color, borderColor:props.borderColor}}>
                    {/* <Link to={props.to} style={{textDecoration: 'none', color:props.color}}> */}
                        <h1 style={{color:props.textColor}}>
                            {props.text}
                        </h1>
                    {/* </Link> */}
                </div>
            </nav>
        </>
               
    );
}