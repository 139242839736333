import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import './../../Pages css/GoogleSignIn.css'
// import { response } from 'express';
import Button from './../../Button.js'
import ReactGA from 'react-ga';


const whiteUpload = () => {
  ReactGA.event ({
      category: 'Button',
      action: "Click White upload button"
  })
  alert('Here comes the fun part, you get to upload 4 photos.');
  
}

// const uploadInstructions = () => {
//   alert('Welcome to A51! You may upload up to 4 photos. You must upload all of your photos before closing this tab. If you do close the tab before you are finished uploading, you must reupload all 4 of your photos. After uploading you will receive an email with a link to your gallery. Your link may not come for a few days.');
// }

function SignIn() {     

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);


  // **you can access the token like this**
//   const accessToken = gapi.auth.getToken().access_token;
//   console.log(accessToken);

const [active, setActive] = useState(false);
const [inactive, setInactive] = useState(true);

  const onSuccess = response => {
    console.log('SUCCESS', response);
    console.log('Email', response.profileObj.email);
    //
    localStorage.setItem("Gmail", response.profileObj.email);
    console.log('set local storage');
    console.log('local storage email:', localStorage.Gmail);

    var id_token = response.getAuthResponse().id_token;
        console.log("ID Token: " + id_token);

    setInactive(false);
    setActive(true);

        // document.location.href = '/Upload'
  };  
  const onFailure = response => {
    console.log('FAILED', response);
  };

  const onLogoutSuccess = () => {
    console.log('SUCCESS LOG OUT');
    alert('You have been successfully logged out');

    setInactive(true);
    setActive(false);
  };


  return (
    <div className='signIn'>
      
        <div className='google-buttons'>
            {active ? (
                <div className='Logout'>
                    <GoogleLogout
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    onLogoutSuccess={onLogoutSuccess}
                    disabled={inactive}
                    icon={true}
                    />
                <div className='Upload-button'>
                    <Button path="/Upload" text="Upload" backgroundColor="White" color="black" onClick={whiteUpload}></Button>
                </div>
                    
                </div>
         ) : (
                <GoogleLogin className='login'
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                // render={renderProps => (
                //     <Button onClick={renderProps.onClick} disabled={renderProps.disabled}>Test button</Button>
                // )}
                onSuccess={onSuccess}
                onFailure={onFailure}                
                isSignedIn={true}
                disabled={active}
                icon={true}
                />    
            )}                  
        </div>
    </div>
  );
}

export default SignIn;
