import React from 'react';
import Button from '../Button';
import InfoCard from '../infoCard';
import { saveAs } from 'file-saver';
import './../Pages css/Color Pick.css';

function ColorPick () {

//red
    const red100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mmaqakc8xwn6JAm772oBTU3GbcivBR_RQg2gfgFvMLKENVS5rGq7gwwYOyWAJr3_NwS1KwzQVKzq0lqA8BjQBb9kf1b8JaYJeGSMGI0NN_vJIUMM2ZkZvRAmvt36P-2oJCAf6sGNPBoz-JjbVCjJluFpWmMrWVB5mScpbVSTEDY8h4KXlHObLOj1RgGeJkEtG?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FF0000"
        );
    };
    const red80 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mR4A5vQAXL5JTPBSgW_dcGsevO4DTTfdqDUS2UwxFaprEuv-ohmfVIjEHbQtzYYRKYljygW8qYCcGnsQfBSH0sCQBYktFjYSLGCXhJ_n17sSO78XjVIkH2wCrExR0vlwwwz_LlU5eTKumJTVW2Ntkj2N1ciaZf-ogIvzjkKJ0GVzBXPjdlPeSJjpL6EfB7GRw?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FF3333"
        );
    };
    const red60 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mbu0F6jQmv89207xYTLd_JciRB8kmfOLXn65bpZtrVF4ykubVF191viLtTCpOLiIjr2yloGGw_3CWpCYFU7Y9AjuLpg5xsS7Ke9ne6rJsIPRzk6mHmS2q91i3t7SUjaK9ulDD2FJpK4eyWZCtS2j5dt7-RL-sHSxIWoEuTUMYyodGreFAWGhQAIC2IfjcAV9i?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FF6666"
        );
    };
    const red40 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mXyPG-8X3DTyVgYZOQSdbpoY6eSVDUFyXOOojQTMkcPOEqo1CsFKs2zGNkXAbkhLxdRYe49gmCCBI7gVM1d0xnibqY48b9BHr9nNwviPUzVyWR3LXTj-9H33JH7MWmW_dE9dSx-JCJCA25QALMCEIxECdJ40LnkU7I5CrK31UeW7ZmiFAfNhlwB2K_MZ6u7Mw?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FF9999"
        );
    };
    const red20 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mKsR-4KJlyTZWQ4xAaBlnVigFIEKMJj3vHCOhiL4aPdFCd4htnUHDjfYBcQR__5FQDGR9irB6vdtucuJ04Jl0KH42z_P6kGaX5wyf9rzOkbcGLIRUARfbP_cN2-MTt3jH52G8G_HNO83hocuHIQmyGiRStTMQjWaSAKul5Do3XOgnivf2JTxAqD_5Y6JdHnDk?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFCCCC"
        );
    };

//orange
    const orange100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mIWxxSPkVUquWiPxIfck2TlrxI-zOzWe2KGifASeROJIjxE1uAF8qmNrV467zu4v6F_5GsKbYUqY1ghvlGRjI3j1EPkteP6-qwhO8OwG_urqtOJRtq7Dn4TaJhT_ssHRVEzPAgK9A435AEKcxZDN1dVW5l33MoQfcQDpCul7XWrCg3EsNMiB4ukxtfV1xstcA?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FF6600"
        );
    };
    const orange80 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mAAlTPIo8XVzvuZ8boadMyDmRhUckQWpIYscRW8UIbXMubq5UDmavcLyWqimakuILy9Pwlb1YkcxU4Gt5iBNhnFbZO3S2Ny1pS3Qhwj64NFfRu85_ukfrQ6w5P8x3YNgjrSvNeoFSWeTXzIax6MGRoaeVYy1XhnKVzAuIgAMx0yJ1jT6IXTIQS8Oi5GXHKlZd?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FF8533"
        );
    };
    const orange60 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mlkZ6loxD0LQG_dbgwnQH0JJ7mA3lGqaoPOh5tztm-TwkT3Ah0gvk_5d8aqO2xSKuKPauAupkm2xtL7nsqNwGfC4XUWXEgqk_hVpbTjZqHxKRlArGbl9NksRIwGvxAq69INfS_hIcDIpklczfdCiLGZrBC3HNClWnettQ_S4adg8vsHVZP5UXJFabMpt8F9Ve?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFA366"
        );
    };
    const orange40 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mEwm14P4m1Jc5_YzSpUt7-Z_xEmRWwPo9jnu8-LHrx9P0VDi56mx-cvtxh8yzvqKlp7sFvDMKaBcqRWvAD-6iz8aTQF40hS1MeXtExY4VHTi-LcynDVtzoIZZfWbF-TtRz1gCXYxwBexz9-3b6PkQ2ow3z2oR5jpHo4_dkSs0ibyydIzRM5XjeIQ1gT52mFzS?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFC299"
        );
    };
    const orange20 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mPPfI0fiTGN_nN9h8Cwmvf6fZ3ie1Y_yr7QP7SabtFRO6fatsPoSpL7dDLSYzindfmdZWdERTCBnIHCYT-lb62nynt9DsIRdug8bCt_03k_AitApko5BnWvqzDw-xXQss9X1MVuf-8E2iEndGM5uU0Q2FGtoXOHwSreK3SqSWvRp2Hez7a2Y0n00QVs4jZWx5?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFE0CC"
        );
    };
//yellow
    const yellow100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mPeMZ-JbL4qer-7ywRWfmrQ2EJ56LWF4q0rOJYqOHmR6qHjuUY1SUZ3OdDUpa3CjaHYfwHbSNz7s1gE18dB6ZSL_AnRr8grDxsbZuJiIQAz6cvEqkQu-QUaePPFxp3XpoUHv07AWBXtJDeJQrZybq0ydXpF6xUMcC844Qxa14niy9LYKQ2TpA4hLnBp2lsAaY?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFCC00"
        );
    };
    const yellow80 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mC9Wdpj75_jn9vCOzhrUO7SePqBmShDJg-R2qpTAZg7P6hcrDZ7B3Kl23uuy6HZoqoy0CxX29PG8Z7TEniG1x_yB3KNfsgZV7Ck_XKe5apXrd8klLhfQMO5olKGBTEmZ2IOmYrERobbnCbr96KZZrylW7F2kwKMjpYNghAHvQHo4msJpK1kYlDzG89D344n5T?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFD633"
        );
    };
    const yellow60 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mUmH6shnWmyXZYpTTIUI_pCcTw8XD6El0pCaNeGQZxyhmA0iriw-y_bubBT1ukIXFbe_9Dvrc4wLJEToR_nwUPZMU1GhpxAWTaFaOLke5Jb3SED8oDzNf088hiDydpu-T6n-F8a9EvnvqAtx4vYq8jhbXd9twCooFHKMLZcu5ggKNjyOuj3FUtNS3xTRW6laI?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFE066"
        );
    };
    const yellow40 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mFjvyRJzk-KBIvBpuGeMSq9baLWQd5RDnBiuWwJGfNgnOyr4A1A_M0cesBQSGieMiRRjwgLhJW8MCTCQuC1uRnFcAyc8uYaavNHWdUZoYUg1GNuwkqmo813rp37vlEw7_FliyGP-dN-spZ4B1J91aIn9Zsln97V6l0HCtnNlUWESelmpnRVdB4wAgRWkTtxM4?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFEB99"
        );
    };
    const yellow20 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mcTIp8RhmwEXaUSDaNNRFTfd4WrmyJQpneM2zjFKjpfJO3AZda0w1rTHq93RFhcM70-gCeD314VbhgXs9FedNCLBDAJAaT6D4hYUPStx22ce_7RhCTRHWviO_6syS_mnVOX7K2ZXetAUWI5agoO2hvGlxk1lonfK-2s7guJ_GorVPbckEukZUHTJuxxIdWxkE?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFF5CC"
        );
    };
//green
    const green100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mrPYptiOJHq-D8rwD7om-1YZ3dLqNjr93FAVG_r1ze1eQJHNq_lgcZumgtMySo2sbbvBbUhAVF9FbcT_A6Nt1AOYZWcUK_WxK0ckxHFCWeNUXhNotEQWGzFITtrspXDGGRjqQGEwTl_JV7SC-m8A5G56TCIAclH_n3MgyFWbckb-d8kkMtoEdipFxVF2zztha?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #00D725"
        );
    };
    const green80 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mdusVlV_fD2K1EsYQXWHfoHmKi0t1BYmxwa4HGpKZxQ0liBOWf9v_qxGlpb_PFz2dSnvRnfnpl-ucIO2cG40Vf45CnKgZtcE4lZBlNjAKejzITVlExmof9deAvIfAYhNY-J5TpGsQkNzD2e9CIhZokiTB9UhDBPJNAi2zEdAen6pUpYDqaWOPSCzIbtO3TqZK?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #2BD647"
        );
    };
    const green60 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mBSVtqwP23N8Q2ep7S8cvKMIcZ_U3GitSyjQ9rjX2OzOLP9qW8ddEFvCguguMKQ2-tU2FXXerB_pfgDIWnTwXq60zVFVArYsO75zv_K9RIpEXUGMWInXYbVJnCJ65Le6-qIDFSMLRNw89hb8DY7cxLx72MScn248IUesp-QDTLQgrN3k35ZvBMsh7EuC2Ua9s?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #56D66B"
        );
    };
    const green40 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mE4lt5O7guOLQDaHkCCOZB5oCyz4bFzuj5pP_nkq83rZr9Dq8moaZIT2todjNJqOkGkRLudI1NfYvgqpI2ffAKxEDxvRnPIh7tucaA-EFIiTW4nEilnhXDG_nQvTztxJyCi1Rqhwv5oG0KurFjoJUQ40bJTykjo7jkb9R9vB3YgYaetuKVi20LYb0DEmcfBS-?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #81D68F"
        );
    };
    const green20 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mixkf0juJN0QUROIPvFH5tz7AEN-bGREnMKK0XnjfcoUcGbP3wadfgHQ1HJS1XUAIkROIwDY-Co348co2CLefadQhVExOBXgUNAvHEx8SqpMeAl_mE9TVSfjUKarx-o8WFcXyW6ozsKnLU1VTw7umOJm8hbZ6OtrSD3ZTJlWiDITHpe2ds3vTJauB-0aJP42d?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #ABD6B3"
        );
    };
//blue
    const blue100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4m4-54aFVd9pkqOeb9NFnyd7rgtpHq43UoqNk7t5Ryxb23e8lBD1lpvMffDyWbiw8CN2r83uypXdn3jgSqeeyHX1ez579FB1FGX58fbFy1IXQnhi2pWFmuKL27DithLZxEq7vUycrciUvPYKJNkpUpLJnFEgsLUuaageRfPfNHA0eByGtsFvkWfCRiPGI4Iv9T?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #00AE6"
        );
    };
    const blue80 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mr9uT1ebOlrroBGWZWQprel9jVXVqW-e1C2vpAR_K_VAReFR-npDvKCtrNvI-z0mjsp52KO8vVQKjS3w_PAxiaXgFNwpyAMVtwPE36vSMQC1_DoL8RDZC4gOMw6Lfswsizx_EIgiQlQb9HxCgpWhGLbfH3QS3PIGtknl_IFPjzLxDwU5Z8gLSnBwB74Tmd7es?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #2E9CE6"
        );
    };
    const blue60 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mGEF6z0Q2O7P9x8t7ZdHV4sWLoUzdglB2Zbk5nYtdaYTXop9AjrVr93AqzbMbSXrTeCdGqil4h2tIKOSvxtE2Y3mQ773coUiqWzWtU4C1UhXJWxHQoTgUGl_IJCOK8pvRbc9SqtN99dRalY9nDOWCwZ7lvqxgSFKOvSEAC9TVUASzVfkHBa1ShWUzjtGa5Lrb?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #5CAEE6"
        );
    };
    const blue40 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mAUJsMZ7LUfFHhAaKCx2uyoyaLHYIJs9oSv5__y7nPds6LARwPMItfSPzVcyI1IrfiJq9ITWNqT4siNOHsDhjb3XkLvE5P8sXXwy4cpFwkbRhrdI9g3Ihwq_p71K2l8WYpgNYGPUHnLgRVN11epHzizLbgEsLN1FEXJzq-IaKWIcWehHlDRZFJXs7kTMI1_I3?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #8AC1E6"
        );
    };
    const blue20 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mJsydyIRDyUhIYsvnhWMKuyGua8RJX-3IUruDaDk-BanOGOitM-2AlCZ06ltRUR6z5jQg2j8o5vsJvLDBRJQEhK6MWZZekDJNRFZj_BZhSRCj-BCWeP8JuH03iBwdawDD8ptv79XdhTyFkNF6UN7ge6agVbbcrRnTLqhFv9Tahb-WadL9koCOhZ8DbQXLUybT?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #B8D3E6"
        );
    };
//purple
    const purple100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mju0PLuWjo7wMKgLvX3YuUTXo8aquN0PEJff_ySfOZUTGiDX5X92CXQkrANJvfOVP5jDNXUoA4RGxNsqVkoOogFatymNMulgyiCMwEV_0zQfsbh5eE-s16W-kp_rMhYazcOxV9WOfAOr2R195b89lpivDarGP8S8g07hQKXIWuUTwXv5mBmsxAw9XhNC8caHp?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #B501EB"
        );
    };
    const purple80 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mCmm_8VXd7nuaTiObyNVCdro2DF0dQunmcbVhmLJK6_3xhsQY8SQeL7Z-N_kfKsVWyBdfmqOfb9zGcxMz0bUEAsq48PhHbH2q-9-i4B6fwGJKJyk5kqRQlAogB-lexFq6qebj7lNv40f35FV2YZ6bYt9WjUEiIKhIs_HpiRua5tc1Dw9MsSMVCvE2BG3Lq06d?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #BF2FEB"
        );
    };
    const purple60 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mEDQz9-5tbJiVHJ52upjJLwZvXpLFZGpvDTbJ587hjw9FFYeSr1iGLaDvoAv-tb70oe05hOEexhAG_dz5lNw70-2c4AxRH1lrVdMyhDynKzGX70zGGmy2YiWeOdinUNhZITIUjt5q2NarGv5lyZH3n9-7ZCucJgHSjvkxyFBdxrzgSN7vWX_iNcyAtH8u-6bP?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #CA5EEB"
        );
    };
    const purple40 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mTao__gPvmhpgSGx3IrLkJW2BnFl_HU8CrcXQYlOx44Oa74G1ROsN6gJQTznzrJsfl5yTbERLAZaKaYqL_VDw890hUvZbH-ptq_N_y3AONiX2MsQT7wr3atB5xLcw-WbavWMMuaiBvncKsq5JgJmY47ET5nYbnHmueR0sBVTCzW-s4M19yIwYeDXy3941-rwI?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #D58DEB"
        );
    };
    const purple20 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mHBTSx9hwTTYV2UcXx1KUsPNo1lSzndGDeQwcwapIZUW6PJoqZ_Gwfmo-phpVEtlKIYue4K1H144qtyQkvdlhu7oScVNEaEKmL1W_Kvkx1S3jDS8Son91AZHGUeANmHYqvHfz518xTvBV1NBziDInenL3XBfWFsEASFOKLp0gDMa1OOAJxzBDTk6CQub8LysQ?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #E0BCEB"
        );
    };
//black
    const black100 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4me69S4LdfmkP2LNw7WHEZfyJjGJcTb2cb3s1ntxIxpJGCkncNZvghJ2jWGMVAcjAiwjUFeS7VxSw2Y4XnY_asRc8HQ6LoquRbM0HKrnYxnggLnCzjxKuFTLfPnz2cWvelPp-Gy5WrpUnQq_cnO5XqaIuJi5wZvSm74JfDokhhWHdO-QJAJsONQCQojBd_Jf-r?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #000000"
        );
    };
    const black75 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mvy5CQU7H7JTvohCTyIWg9r7kPzVc-s1Oo3XH81yI4tdXnmavD66JBBHHDMQufIzC9pv4YzKHHaKliMlqyhTVE5XzFAEnpsFDGK6TZEpMvjGb-EKCR6606QAPfHIadOMi8GsA_rXGVFaUIIsnZqR2JVRm1DZURX-1n0gda-dPc6tbkZbo3puBlngXdTyoPHX_?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #404040"
        );
    };
    const black50 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mwSN-NePJkmnlP0914B3fpEpwBmRuVNGRoKLSbQD706BLbZEjrDFevFpPkp8GW4LRp_PejDwoduM3fSuZqeJew5dtFCQ5Wu0kRrzj_lsXC2vT4T7mun6fLuQ4tPiFbtN-BEsh98P0WMwnZvP2udG39jdcGDgAnYv32DUEzyjhY8J51l_TNU6Z0kGVTF6ZZhyZ?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #808080"
        );
    };
    const black25 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4mvSWZWzS-ecl1jH4IRO7B0xLS97usHlKB3RtfXKYFTuE8cnI08Bs1mXCiB2-MsJwDMynOMPyaAKsLUxJQyglR82NlbrixKme1qNOcu1co88rnWs3XH_xJ0z5H4QQ0p2qFD_SSDGQtAuTIRdvyGItt_RMfbRQmq1YaWi85zl35dHANEP4iu5J9NNu5vhsGOZFf?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #BFBFBF"
        );
    };
    const black00 = () => {
        saveAs(
            "https://dsm01pap005files.storage.live.com/y4msw4ZbMi1IPfpZmf2W2LhIxV_aVAtpjLcTN1Q7G5o_r1D7aKxbiPiN91BcFEo-2QOY-1k4EgjLayn9SUxxMSagNOlvBgVeMJ3FWYXfSDgIez1KcFmzem96MCmvATiOWl2IQY0CF7dU9tOVWr1--uUF-ywipuDdKfZxQjg6cPA8QqtQfM4X-lKmqvcJhLiL3os?width=1954&height=412&cropmode=none",
            "A51 Wall 2 #FFFFFF"
        );
    };
    return (
        <div className='colorPick'>
            {/* <h1>Next steps</h1> */}
            <div className='cards'>
                <div>
                    <InfoCard
                        text='Click a color to download'
                        color='white'
                        textColor='white'
                    ></InfoCard>
                </div>
                <div>
                    <InfoCard
                        text='Pick which wall'
                        color='white'
                        textColor='white'
                    ></InfoCard>
                </div>
                <div>
                    <InfoCard
                        text='Upload your custom color'
                        color='white'
                        textColor='white'
                    ></InfoCard>
                </div>
            </div>
            <div className='colors'>
                <div className='red'>
                   <button onClick={red100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FF0000", color:"white", borderRadius:"0px", borderColor:"#FF0000", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FF0000</button>
                   <button onClick={red80} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FF3333", color:"white", borderRadius:"0px", borderColor:"#FF3333", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FF3333</button>
                   <button onClick={red60} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FF6666", color:"white", borderRadius:"0px", borderColor:"#FF6666", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FF6666</button>
                   <button onClick={red40} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FF9999", color:"#000000", borderRadius:"0px", borderColor:"#FF9999", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FF9999</button>
                   <button onClick={red20} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFCCCC", color:"#000000", borderRadius:"0px", borderColor:"#FFCCCC", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFCCCC</button>
                </div>
                <div className='orange'>
                   <button onClick={orange100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FF6600", color:"white", borderRadius:"0px", borderColor:"#FF6600", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FF6600</button>
                   <button onClick={orange80} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FF8533", color:"white", borderRadius:"0px", borderColor:"#FF8533", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FF8533</button>
                   <button onClick={orange60} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFA366", color:"white", borderRadius:"0px", borderColor:"#FFA366", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFA366</button>
                   <button onClick={orange40} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFC299", color:"#000000", borderRadius:"0px", borderColor:"#FFC299", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFC299</button>
                   <button onClick={orange20} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFE0CC", color:"#000000", borderRadius:"0px", borderColor:"#FFE0CC", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFE0CC</button>
                </div>
                <div className='yellow'>
                   <button onClick={yellow100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFCC00", color:"white", borderRadius:"0px", borderColor:"##FFCC00", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFCC00</button>
                   <button onClick={yellow80} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFD633", color:"white", borderRadius:"0px", borderColor:"#FFD633", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFD633</button>
                   <button onClick={yellow60} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFE066", color:"white", borderRadius:"0px", borderColor:"FFE066", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFE066</button>
                   <button onClick={yellow40} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFEB99", color:"#000000", borderRadius:"0px", borderColor:"#FFEB99", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFEB99</button>
                   <button onClick={yellow20} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFF5CC", color:"#000000", borderRadius:"0px", borderColor:"#FFF5CC", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFF5CC</button>
                </div>
                <div className='green'>
                   <button onClick={green100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#00D725", color:"white", borderRadius:"0px", borderColor:"#00D725", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#00D725</button>
                   <button onClick={green80} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#2BD647", color:"white", borderRadius:"0px", borderColor:"#2BD647", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#2BD647</button>
                   <button onClick={green60} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#56D66B", color:"white", borderRadius:"0px", borderColor:"#56D66B", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#56D66B</button>
                   <button onClick={green40} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#81D68F", color:"#000000", borderRadius:"0px", borderColor:"#81D68F", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#81D68F</button>
                   <button onClick={green20} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#ABD6B3", color:"#000000", borderRadius:"0px", borderColor:"#ABD6B3", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#ABD6B3</button>
                </div>
                <div className='blue'>
                   <button onClick={blue100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#008AE6", color:"white", borderRadius:"0px", borderColor:"#008AE6", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#008AE6</button>
                   <button onClick={blue80} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#2E9CE6", color:"white", borderRadius:"0px", borderColor:"#2E9CE6", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#2E9CE6</button>
                   <button onClick={blue60} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#5CAEE6", color:"white", borderRadius:"0px", borderColor:"#5CAEE6", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#5CAEE6</button>
                   <button onClick={blue40} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#8AC1E6", color:"#000000", borderRadius:"0px", borderColor:"#8AC1E6", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#8AC1E6</button>
                   <button onClick={blue20} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#B8D3E6", color:"#000000", borderRadius:"0px", borderColor:"#B8D3E6", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#B8D3E6</button>
                </div>
                <div className='purple'>
                   <button onClick={purple100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#B501EB", color:"white", borderRadius:"0px", borderColor:"#B501EB", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#B501EB</button>
                   <button onClick={purple80} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#BF2FEB", color:"white", borderRadius:"0px", borderColor:"#BF2FEB", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#BF2FEB</button>
                   <button onClick={purple60} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#CA5EEB", color:"white", borderRadius:"0px", borderColor:"#CA5EEB", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#CA5EEB</button>
                   <button onClick={purple40} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#D58DEB", color:"#000000", borderRadius:"0px", borderColor:"#D58DEB", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#D58DEB</button>
                   <button onClick={purple20} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#E0BCEB", color:"#000000", borderRadius:"0px", borderColor:"#E0BCEB", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#E0BCEB</button>
                </div>
                <div className='black'>
                   <button onClick={black100} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#000000", color:"white", borderRadius:"0px", borderColor:"#000000", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px', border:'0px'}}>#000000</button>
                   <button onClick={black75} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#404040", color:"white", borderRadius:"0px", borderColor:"#000000", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px', border:'0px'}}>#404040</button>
                   <button onClick={black50} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#808080", color:"white", borderRadius:"0px", borderColor:"#000000", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#808080</button>
                   <button onClick={black25} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#BFBFBF", color:"#000000", borderRadius:"0px", borderColor:"#000000", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#BFBFBF</button>
                   <button onClick={black00} style={{margin:"0rem", padding:"3vh", width:'12rem', backgroundColor:"#FFFFFF", color:"#000000", borderRadius:"0px", borderColor:"#000000", fontFamily: "navigo, sans-serif", fontSize: "15px", border:'0px'}}>#FFFFFF</button>
                </div>
            </div>            
        </div>
    );
}

export default ColorPick;