import React, { useState } from 'react';
import { db, collection, addDoc } from '../../Firebase';
import Button from '../Button';
import './../Pages css/AuctionForm.css';
import {getAuth} from "firebase/auth"

import CommerceUpload from './Commerce Upload';
import PhotoInfo from './PhotoInfo';
import GetFormData from './GetFormData';

const AuctionForm = () => {
  GetFormData();

  const auth = getAuth();
  const user = auth.currentUser;
 
  const [CommerceUI, setCommerceUI] = useState(null);
  console.log(CommerceUI);

    const saveAnswer = (event) => {
        event.preventDefault();
    
        const elementsArray = [...event.target.elements];
    
        const formData = elementsArray.reduce((accumulator, currentValue) => {
          if (currentValue.id) {
            accumulator[currentValue.id] = currentValue.value;
          }
    
          return accumulator;
        }, {});
    
        // db.collection("SurveyResponses").add(formData);
        try {
            const docRef = addDoc(collection(db, "Auction Forms Collection", "Auction Forms Document", "Auction Forms"), {
              formData: formData,
              Title: formData.Title,
              Price: formData.Price,
              Height: formData.Height,
              Width: formData.Width,
              Count: localStorage.formCount,
              Info: formData.Info,
              Email: user.email

            }).then(alert('Your information has been submitted successfuly')).then(setCommerceUI('true'))
            // console.log("Document written with ID: ", docRef.id);
            // console.log("Document written with ID: ", formData.Title);

          } catch (e) {
            console.error("Error adding document: ", e);
            alert("Your information could not be submitted, please make sure you are logged in");
          }

      };
    return (
       CommerceUI === 'true' ? (

       <CommerceUpload/>
       
       ):(

        // <PhotoInfo/>

        <div className='auctionForm'>
            <div className='completion'>
                    <div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'#00D725', margin:'2rem', marginBottom:'4rem'}}></div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'white', margin:'2rem', marginBottom:'4rem'}}></div>
                        <div className='circle' style={{height:'3rem', width:'3rem', borderRadius:'1.5rem', background:'white', margin:'2rem', marginBottom:'4rem'}}></div>
                    </div>
                </div>
            <h1>
                Photo details
            </h1>
            <form onSubmit={saveAnswer} onstyle={{alignItems:"center"}}>
                <div className='title'>
                    <input type="text" id="Title" placeholder="Title" required='true' style={{padding:"1vh", width:"30vh", margin:"1rem"}}></input>
                </div>
                <div className='price'>
                    <input type="number" id="Price" placeholder="Price" required='true' style={{padding:"1vh", margin:"1rem"}}></input>
                </div>
                <div className='size'>
                    <input type="number" id="Height" placeholder="Height (in)" required='true' style={{padding:"1vh", width:"15vh", margin:"1rem", marginRight:"0rem"}}></input>
                    <input type="number" id="Width" placeholder="Width (in)" required='true' style={{padding:"1vh", width:"15vh", margin:"1rem", marginLeft:"0rem"}}></input>
                </div>
                <div className='info'>
                    <input type="text" id="Info" placeholder="Additional information..." required='true' style={{padding:"1vh", width:"30vh", margin:"1rem"}}></input>
                </div>
                <div>
                    <button style={{
                        margin:"1rem",
                        padding:"1vh",
                        backgroundColor:"#00D725",
                        color:"white",
                        borderRadius:"7px",
                        borderColor:"black",
                        fontFamily: "navigo, sans-serif",
                        fontSize: "15px"
                        }}>
                            Submit
                    </button>
                </div>
                {/* <Button text="Submit" style={{color:"white"}}></Button> */}
                {/* <Button text="Try the demo" onClick={saveAnswer} backgroundColor="none" color="white" borderColor="#ffffff70"></Button> */}

            </form>
        </div>
        )
    )
}

export default AuctionForm;