import React from 'react';
// import './../App.css'
import './Pages css/BlogCard.css'
import { Link } from 'react-router-dom';


export default function BlogCard(props){
    return (
        <>
            <nav>
                <div className= "Blog-card">
                    <Link to={props.path} onClick={props.onClick} className="blog-link" style={{ backgroundColor:props.backgroundColor, textDecoration: 'none', color:props.color }}>
                        <h1>
                            {props.header}
                        </h1>
                        <p1>
                            {props.description}
                        </p1>
                    </Link>
                </div>
            </nav>
        </>
       
    );
}