import React from 'react';
import './../Pages css/Faqs.css'
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './../Pages scss/Faqs.scss';

function Faqs() {
    return(
        <div className='faqs'>
             <h1>
                 FAQs
             </h1>
             <Accordion style={{width:"50%"}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>How many photos can I upload?</Accordion.Header>
                        <Accordion.Body >
                            You can upload up to 4 photos
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                        <Accordion.Header>How much does a virtual gallery cost?</Accordion.Header>
                            <Accordion.Body>
                                Currently A51 is free. You can have up to 4 photos in your gallery at a time.
                            </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                        <Accordion.Header>What type of files can I upload?</Accordion.Header>
                            <Accordion.Body>
                                Currently A51 is compatable with:
                                <br></br>
                                .jpg
                                <br></br>
                                .jpeg
                                <br></br>
                                .png
                            </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                        <Accordion.Header>My photos are not showing up in my gallery!</Accordion.Header>
                            <Accordion.Body>
                                Make sure you are logged into the desktop application with the same email you uploaded your photos with.
                                <br></br>
                                <br></br>
                                If you are logged in with the correct account, make sure you uploaded compatable file types.
                                <br></br>
                                A list of compatable file types can be found above.
                            </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                        <Accordion.Header>Can I change the photos I have uploaded?</Accordion.Header>
                            <Accordion.Body>
                                Yes! Just upload your new photos, and you old ones will be replaced.
                            </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default Faqs;