import React from 'react';
import './../../App.css'
import './../Pages css/Footer.css'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import OutlinedButton from '../Outlined Button';
// import { Link } from 'react-router-dom';


export default function Footer(){
    return (
        <div className="Footer">
            <div className='footer-row'>
                <div className='socials'>
                    <div style={{marginRight:".5rem"}}>
                        <OutlinedButton path="/Download" text="Downloads" backgroundColor="#000000" color="white" borderColor="white"></OutlinedButton>
                        <OutlinedButton path="/Faqs" text="FAQs" backgroundColor="#000000" color="white" borderColor="white"></OutlinedButton>
                    </div>
                    <div>
                        {/* <Link to='/Medium'>Blog</Link>
                        <Link to='/Mission'>Mission</Link> */}
                        <OutlinedButton path="/Medium" text="Blog" backgroundColor="#000000" color="white" borderColor="black"></OutlinedButton>
                        <OutlinedButton path="/Mission" text="Mission" backgroundColor="#000000" color="white" borderColor="black"></OutlinedButton>
                    </div>
                    <div>
                        <OutlinedButton path="/Instagram" text="Instagram" backgroundColor="#000000" color="white" borderColor="black"></OutlinedButton>
                        <OutlinedButton path="/LinkedIn" text="LinkedIn" backgroundColor="#000000" color="white" borderColor="black"></OutlinedButton>
                    </div>
                </div>
                <div className="email-mobile">
                    <div className="email-text">
                        <div>
                            <p1>
                                Recieve future updates
                            </p1>
                            <div>
                                <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} style={{ color: 'blue', width: 100, height: 500}}/>
                            </div>
                        </div>
                    </div> 
                </div>
            
            <div className="email-full">
                <div className="email-text-full">
                <div>
                        <p1>
                            Recieve future updates
                        </p1>
                        <div>
                            <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} style={{ color: 'blue', width: 100, height: 500}}/>
                        </div>
                </div>
                </div> 
            </div>
            </div>
            <div>
                <h1>
                    Copyright © 2022 A51 Industries Inc. All rights reserved.
                </h1>
              
            </div>
        </div> 
    );
}