import React from 'react';
// import './../App.css'
import './Pages css/Button.css'
import { Link } from 'react-router-dom';


export default function Button(props){
    return (
        <>
            <nav>
                <div className= "Button">
                    <Link to={props.path} onClick={props.onClick} className="link" style={{ backgroundColor:props.backgroundColor, textDecoration: 'none', color:props.color, borderColor:props.borderColor, display:props.display, height:props.height, width:props.width, margin:props.margin, padding:props.padding}}>
                        <h1>
                            {props.text}
                        </h1>
                    </Link>
                </div>
            </nav>
        </>
       
    );
}